import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Select from './Select';
import { useController } from 'react-hook-form';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getPMs = async () => {
  const response = await instance.get('/private/admin/employees');
  return response.data;
};

const PMSelect = ({ control }) => {
  const { data } = useQuery('employees', getPMs);

  const options = useMemo(
    () =>
      data
        .filter((member) => member.isActive === true)
        .map((member) => ({
          label: member.employee.name,
          value: member.id,
        })),
    [data]
  );

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'pm',
    control,
    rules: {
      required: '*Project Manager is required!',
    },
  });

  return (
    <Select
      placeholder="Project Manager"
      error={error}
      {...field}
      options={options}
    />
  );
};

PMSelect.propTypes = {
  control: PropTypes.object,
};

export default PMSelect;
